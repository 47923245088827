import React, { useState } from 'react';

import { Gallery } from "react-grid-gallery";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import { Image } from "react-grid-gallery";
import { json } from 'react-router-dom';

export const ImageGallery = (images) => {
  const [index, setIndex] = useState(-1);
  images=images.images;
  console.log(Image)
    

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  return (
    <div className='gallery-wrapper'>
        
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
          rowHeight={300}
          lazyLoad={true}
        />
      {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
        )}
      </div>

     
  )
}
